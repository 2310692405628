import '../styles/index.scss';

const Sqrl = require('squirrelly');
const BOOSTER_DOMAIN = 'booster.profitroom.com';
const ANIMATION_TIME = 500;

function getTransformOfElement(domElement) {
    return window.getComputedStyle(domElement).getPropertyValue("transform").match(/(-?[0-9\.]+)/g);
}

class AppBooster {

    constructor() {

        // if (this.isMobile()) {
        //     console.log('Booster is disabled in mobile devices.');
        //     return;
        // }

        this.options = this.setDefaultSettings();
        this.mobileMode = this.isMobile();

        this.feed = [];
        this.messages = [];
        this.$boosterWrapper = null;
        this.$boosterMassages = null;
        this.$boosterApp = null;
        this.styleLoaded = false;
        this.$boosterDOM = null;

        this.messagesState = {
            activeMessages: [],
            limit: 3,
            index: 0,
            isStopped: false,
            isAnimating: false,
            isAdding: false,
            timeOut: null,
            isEnd: false
        };

        this.timeoutOnShowMessage = 0;

        window.addEventListener('resize', this.debounce(this.onResize.bind(this), 100));

        if(this.options.messages.length) {
            this.feed = this.options.messages;
            this.initializeBooster();
        }
        else {
            this.fetchData().then(() => {
                this.initializeBooster();
            }).catch(function (e) {
                console.error(e);
            });
        }
    }

    debounce(func, delay){
        let inDebounce;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    }

    onResize() {
        this.mobileMode = this.isMobile();
        this.$boosterWrapper.classList.add('booster-app__wrapper--disable-animation');
        this.toggleMobileModeClass();

        if (!this.messagesState.isStopped) {
            this.recalculatePositionMessages();
        }

        setTimeout(() =>  {
            this.$boosterWrapper.classList.remove('booster-app__wrapper--disable-animation');
        }, 0);
    }

    isMobile() {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    setDefaultSettings() {

        let defaults = {
            url: 'https://' + BOOSTER_DOMAIN,
            siteKey: null,
            lang: this.getDefaultLanguage(),
            env: process.env.NODE_ENV,
            delayShow: 2000,
            icon: 'human',
            feedUrl: null,
            position: 'left',
            wrapper: null,
            instantMode: false,
            messages: []
        };

        defaults.stylesUrl = defaults.url + '/build/app.min.css';

        if (typeof boosterSettings === "object") {
            defaults = Object.assign({}, defaults, boosterSettings);
            if (!boosterSettings.hasOwnProperty('stylesUrl')) {
                defaults.stylesUrl = defaults.url + '/build/app.min.css';
            }
        }

        return defaults;
    }

    getDefaultLanguage() {
        let lang = document.querySelector('html').getAttribute('lang');

        if (lang === "" || lang === null) {
            return 'en';
        }

        return lang;
    }

    fetchData() {
        let feedUrl = (this.options.feedUrl) ? this.options.feedUrl : this.generateFeed();

        if (this.options.env === "development") {
            feedUrl = this.options.devFeedUrl ? this.options.devFeedUrl : 'https://booster.profitroom.local/fixtures-data';
        }

        return fetch(feedUrl, {credentials: 'include'})
            .then(function (response) {
                return response.json();
            })
            .then((responseJson) => {
                this.feed = responseJson.data;
            });
    }

    generateFeed() {
        return this.options.url + '/' + this.options.lang + '/' + this.options.siteKey + '/feed';
    }

    /** Load styles from external resources */
    loadStyles(onLoad) {
        this.preloadFonts();

        if (this.options.env !== 'development') {

            if(document.getElementById('booster-style')) {
                onLoad();
            } else {
                const versionDate = (new Date()).toLocaleDateString()  + '.' + (new Date()).getHours();
                let link = document.createElement('link');
                link.id = 'booster-style';
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.onload = function () {
                    onLoad();
                };
                link.href = this.options.stylesUrl + '?v=' + versionDate;
                document.getElementsByTagName('HEAD')[0].appendChild(link);
            }
        } else {
            // document.getElementsByTagName('body')[0].style.backgroundColor = 'black';
            onLoad();
        }
    }

    initializeBooster() {
        this.loadStyles(() => {

            if(this.styleLoaded) {
               return;
            }

            this.styleLoaded = true;
            this.injectBooster();
            this.eventListeners();
            this.appendAllMessages();

            if (this.feed.length === 0) {
                return;
            }

            setTimeout(() => {
                this.$boosterApp.style.display = 'block';
            }, 0);

            setTimeout(() => {

                if (this.wasClosed() || this.mobileMode) {
                    this.$boosterWrapper.classList.add(
                        'booster-app__wrapper--fade-in',
                        'booster-app__wrapper--minimized',
                        'booster-app__wrapper--disable-animation');

                    this.$boosterMassages.style.display = 'none';

                    setTimeout(() => {
                        this.$boosterWrapper.classList.remove('booster-app__wrapper--disable-animation');
                    }, 0);

                } else {
                    this.$boosterWrapper.classList.add('booster-app__wrapper--fade-in');
                    this.displayNextMessage();
                }

            }, this.options.instantMode ? 10 : this.options.delayShow);

        });
    }

    appendAllMessages() {
        this.feed.forEach((message) => {
            this.messages.push(this.createMessageItem(message));
        });
    }

    destroyBooster() {
        document.getElementById('booster-preload-fonts').remove();
        this.$boosterApp.remove();
    }

    resetBooster() {
        this.forgetClose();
        this.destroyBooster();
        window.app_booster = new AppBooster();
    }

    preloadFonts() {
        const fragment = document.createDocumentFragment();
        const div = document.createElement('div');
        div.id = 'booster-preload-fonts';

        [300, 400, 500, 600, 700].forEach((size) => {
            const preload = document.createElement('span');
            preload.classList.add('preload-font', 'preload-font__' + size);
            div.appendChild(preload);
        });

        fragment.appendChild(div);
        document.body.appendChild(fragment);
    }

    isVisibleMobileNav() {
        const mobileNav = document.querySelector('.mobile-fixed-nav');
        return mobileNav && window.getComputedStyle(mobileNav, null).display !== "none";
    }

    toggleMobileModeClass() {
        if(this.mobileMode || this.isVisibleMobileNav()) {
            this.$boosterDOM.classList.add('mobile-mode');
        } else {
            this.$boosterDOM.classList.remove('mobile-mode');
        }
    }

    injectBooster() {

        let boosterHook = this.options.wrapper ? this.options.wrapper : document.getElementsByTagName('body')[0];

        this.$boosterDOM = document.createElement('div');

        this.$boosterDOM.id = "booster-app";
        this.$boosterDOM.classList.add("booster-app", "booster-icon-" + this.options.icon);

        this.toggleMobileModeClass();

        if(this.options.wrapper !== null) {
            this.$boosterDOM.classList.add('is-wrapped');
        }

        if(document.body.classList.contains('wa-site')) {
            this.$boosterDOM.classList.add('wa-mode');
        }

        // if(this.isVisibleMobileNav()) {
        //     boosterDOM.classList.add('bottom-navigation-mode');
        // }

        // language=HTML
        this.$boosterDOM.innerHTML = `
            <div class="booster-app__wrapper booster-app__wrapper--${this.options.position}">
                <div class="booster-app__inner">
                    <div class="booster-app__avatar booster-app__avatar--${this.options.icon}">
                        <a class="booster-app__close booster-app__hide">
                            <span class="booster-app__close-icon"></span>
                        </a>
                        <div class="booster-app__counter"></div>
                        <div class="booster-app__icon-wrapper">
                            <div class="booster-app__icon-inner">
                                <div class="booster-app__icon"></div>
                            </div>
                        </div>
                    </div>
                    <div class="booster-app__box">
                        <ul id="booster-messages" class="booster-app__messages"></ul>
                    </div>
                </div>
            </div>
        `;

        boosterHook.appendChild(this.$boosterDOM);

        this.$boosterWrapper = document.getElementsByClassName('booster-app__wrapper')[0];
        this.$boosterMassages = document.getElementById('booster-messages');
        this.$boosterApp = document.getElementById('booster-app');

        this.$boosterWrapper.querySelector('.booster-app__counter').setAttribute(
          'data-amount',
          this.feed.length.toString()
        );
    }

    eventListeners() {
        this.closeHandler();
        this.onShow();
        this.onTransition();
        this.onReset();
    }

    closeHandler() {
        let closeButton = document.querySelector('.booster-app .booster-app__close');

        closeButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.closeBooster(closeButton)
        }, false);

        const iconWrapper = document.querySelector('.booster-app .booster-app__icon-wrapper');

        iconWrapper.addEventListener('click', (e) => {
            e.preventDefault();

            if (!this.mobileMode) {
                return;
            }

            if (!this.messagesState.isAnimating) {
                this.closeBooster(closeButton);
            }
        });
    }

    closeBooster(closeButton) {
        if (!closeButton) {
            closeButton = document.querySelector('.booster-app .booster-app__close');
        }

        if (this.messagesState.isAdding || closeButton.classList.contains('booster-app__hide')) {
            return;
        }

        this.messagesState.isStopped = true;
        closeButton.classList.add("booster-app__hide");
        this.hideAllMessages();

        if (this.messagesState.timeout) {
            clearTimeout(this.messagesState.timeout);
        }

        this.rememberClose();
    }

    onReset() {
        this.$boosterApp.addEventListener('reset-booster', () => {
            this.resetBooster();
        });
    }

    onShow() {
        let iconWrapper = document.querySelector('.booster-app .booster-app__icon-wrapper');

        iconWrapper.addEventListener('click', (e) => {
            e.preventDefault();

            if (!this.$boosterWrapper.classList.contains('booster-app__wrapper--minimized') || this.messagesState.isAnimating) {
                return;
            }

            let closeButton = document.querySelector('.booster-app .booster-app__close');

            this.messagesState.isStopped = false;
            closeButton.classList.remove("booster-app__hide");
            this.$boosterWrapper.classList.remove('booster-app__wrapper--minimized');

            this.showAllMessages();
            this.forgetClose();

        }, false);
    }

    onTransition() {
        this.$boosterWrapper.addEventListener('transitionend', () => {
            this.messagesState.isAnimating = false;
        });

        this.$boosterWrapper.addEventListener('transitionstart', () => {
            this.messagesState.isAnimating = true;
        });
    }

    forgetClose() {
        if (typeof localStorage !== 'undefined') {
            try {
                localStorage.removeItem('boosterClosed');
            } catch (e) {
            }
        }
    }

    rememberClose() {
        if (typeof localStorage !== 'undefined') {
            try {
                localStorage.setItem('boosterClosed', (new Date().getTime().toString()));
            } catch (e) {
            }
        }
    }

    wasClosed() {
        if (typeof localStorage !== 'undefined') {
            try {
                let boosterClosed = localStorage.getItem('boosterClosed'),
                    now = new Date().getTime();

                if (boosterClosed === null) {
                    return false;
                }

                if (now - boosterClosed > 3600 * 1000) {
                    localStorage.removeItem('boosterClosed');

                    return false;
                }
            } catch (e) {
            }
        }

        return true;
    }

    displayNextMessage(quickOpen = false) {
        if (this.messagesState.isStopped || this.messagesState.isEnd) {
            return;
        }

        if (this.messagesState.index >= this.messages.length) {
            this.messagesState.isEnd = true;
            return;
        }

        let currentMessage = this.messages[this.messagesState.index];

        const img = currentMessage.$el.querySelector('img');

        if(img && !img.complete){
            img.onload = () => {
                this.display(currentMessage, quickOpen);
            };
        } else {
            this.display(currentMessage, quickOpen);
        }
    }

    display(currentMessage, quickOpen = false) {
        if (!quickOpen) {
            quickOpen = this.options.instantMode;
        }

        this.messagesState.timeout = setTimeout(() => {
            this.showMessage(currentMessage).then(() => {
                setTimeout(() => {
                    this.messagesState.isAdding = false;
                    this.recalculatePositionMessages();
                    this.displayNextMessage();
                }, 0);
            });
        }, currentMessage.data.delay * (quickOpen ? 10 : 1000));
    }

    showMessage(message) {
        return new Promise((resolve) => {

            message.$el.addEventListener('transitionend', function onTransitionEnd(event) {
                event.currentTarget.removeEventListener(event.type, onTransitionEnd);
                resolve();
            });

            this.messagesState.isAdding = true;
            let heightOfElement = message.$el.offsetHeight + this.getGutter();

            if (this.messagesState.activeMessages.length >= this.messagesState.limit) {
                const first = this.messagesState.activeMessages.shift();
                this.hideFirstMessage(first, heightOfElement);
            }

            // Slide up, all current messages
            if (this.messagesState.activeMessages.length) {
                this.messagesState.activeMessages.forEach((ele) => {
                    this.translateElement(ele, heightOfElement);
                });
            }

            if (this.messagesState.index === 0) {
                document.querySelector('.booster-app .booster-app__close').classList.remove('booster-app__hide');
            }

            message.$el.classList.add('booster-app__messages-item--slide-up');

            this.messagesState.activeMessages.push(message);
            this.messagesState.index++;
        });
    }

    hideFirstMessage(firstMessage, heightOfElement) {
        this.translateElement(firstMessage, heightOfElement);
        firstMessage.$el.classList.add('booster-app__messages-item--fade-out');

        setTimeout(function () {
            firstMessage.$el.remove();
        }, 1000);
    }

    createMessageItem(message) {
        const tagName = message.params.button ? 'a' : 'li';
        const closeSpan = '<span class="messages-item-close"><span class="messages-item-close__icon"></span></span>';
        let element = document.createElement(tagName);

        if (tagName === 'a') {
            if (message.params.url) {
                element.target = (message.params.buttonTarget) ? message.params.buttonTarget : '_blank';
                element.href = message.params.url;
            } else if (message.params.buttonOnClick) {
                element.setAttribute('onclick', message.params.buttonOnClick);
            }
        }

        element.classList.add(
          'booster-app__messages-item',
          'booster-app__messages-item--new',
          'booster-app__messages-item--' + message.type
        );
        element.innerHTML = closeSpan + this.getHtmlOfMessage(message);

        let newEle = {
            $el: element,
            data: message,
            hidden: false
        };

        this.$boosterMassages.append(element);

        newEle.$el.querySelector('.messages-item-close').addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.hideOneMessage(newEle);
        });

        return newEle;
    }

    hideOneMessage(message) {
        message.$el.classList.add('booster-app__messages-item--fade-out');
        message.hidden = true;

        setTimeout(() => {
            message.$el.style.pointerEvents = 'none';

            if (this.isAnyVisibleMessage()) {
                this.recalculatePositionMessages();
            } else {
                this.closeBooster();
            }

        }, ANIMATION_TIME);
    }

    isAnyVisibleMessage() {
        const message = this.messagesState.activeMessages.find(function (ele) {
            return !ele.hidden;
        });

        return !!message;
    }

    hideAllMessages() {
        if (this.messagesState.activeMessages.length) {

            if (this.isAnyVisibleMessage()) {
                this.messagesState.activeMessages.forEach(function (ele) {
                    let transformation = getTransformOfElement(ele.$el);

                    if (transformation !== null) {
                        let transformationY = parseInt(transformation[5]);
                        ele.$el.style.transform = 'translateY(' + (transformationY + 100) + 'px)';
                        ele.$el.classList.add('booster-app__messages-item--fade-out');
                    }
                });
            }

            setTimeout(() => {
                this.hideBoosterWrapper();
            }, 500);
        }
    }

    hideBoosterWrapper() {
        this.$boosterMassages.style.display = 'none';
        if (this.messagesState.isStopped) {
            this.$boosterWrapper.classList.add('booster-app__wrapper--minimized');
        }

        this.messagesState.activeMessages.forEach(function (ele) {
            if (ele.hidden) {
                ele.hidden = false;
                ele.$el.style.pointerEvents = '';
            }
        });
    }

    showAllMessages() {
        let existsActiveMessages = (this.messagesState.activeMessages.length > 0);
        this.$boosterMassages.style.display = 'block';

        if (existsActiveMessages) {
            this.messagesState.activeMessages.forEach(function (ele) {

                let transformation = getTransformOfElement(ele.$el),
                    transformationY = parseInt(transformation[5]);

                ele.$el.style.transform = 'translateY(' + (transformationY - 100) + 'px)';
                ele.$el.classList.remove('booster-app__messages-item--fade-out');
            });

            setTimeout(() => {
                this.recalculatePositionMessages();
            }, 0);
        }

        if (this.timeoutOnShowMessage) {
            clearTimeout(this.timeoutOnShowMessage);
        }

        this.timeoutOnShowMessage = setTimeout(() => {
            this.displayNextMessage(!existsActiveMessages);
        }, (existsActiveMessages) ? 0 : 500);
    }

    recalculatePositionMessages() {
        setTimeout(() => {
            const cloneMessages = [...this.messagesState.activeMessages];

            let message = cloneMessages.pop(),
              height = 0;

            message.$el.style.transform = 'translateY(-' + height + 'px)';

            while (cloneMessages.length) {
                if (!message.hidden) {
                    height += message.$el.offsetHeight + this.getGutter();
                }

                message = cloneMessages.pop();
                message.$el.style.transform = 'translateY(-' + height + 'px)';
            }

        },0);
    }

    getGutter() {
        return this.mobileMode ? 10 : 16;
    }

    translateElement(ele, heightOfNewElement) {
        let transformation = getTransformOfElement(ele.$el);

        if (transformation !== null) {
            let transformationY = Math.abs(parseInt(transformation[5]));
            ele.$el.style.transform = 'translateY(-' + (transformationY + heightOfNewElement) + 'px)';
        }
    }

    getHtmlOfMessage(message) {
        switch (message.type) {
            case 'message':
                return this.getMessageTemplate(message.params);
            case 'offer':
                return this.getOfferTemplate(message.params);
            case 'alert':
            case 'custom':
            case 'voucher':
                return this.getAlertTemplate(message.params);
            case 'be-voucher':
                return this.getVoucherTemplate(message.params);
            case 'share-hospitality':
                return this.getShareHospitalityTemplate(message.params);
            case 'direct-booking':
                return this.getDirectBookingTemplate(message.params);
            default:
                console.log('Sorry, type "' + message.type + '")" is not supported.');
                return '<p>Sorry, type "' + message.type + '" is not supported.</p>'
        }
    }

    renderBtn(messageParams, extraClass) {
        let _template = '';
        extraClass = (typeof extraClass === 'undefined') ? '' : extraClass;

        if (messageParams.button) {
            if (messageParams.url || messageParams.buttonOnClick) {
                _template += '<a class="messages-item-button ' + extraClass + '">{{button}}</a>'
            }
        }

        return _template;
    }

    getMessageTemplate(messageParams) {
        let _template = `
                <div class="messages-item-content">
                    <div class="message-item-content__inner">
                        <div class="messages-item-text">{{text}}</div>
                        {{if(options.content)}}
                            <div class="messages-item-content__paragraph">{{content}}</div>
                        {{/if}}
                    </div>
                    {{if(options.icon)}}
                        <span class="messages-item-icon">
                            <span class="messages-item-icon--{{icon}}"></span>
                        </span>
                    {{/if}}
                </div>
            `;

        _template += this.renderBtn(messageParams);

        let compiled = Sqrl.Compile(_template);

        return compiled(messageParams, Sqrl);
    }

    /**
     * @param {{
     *   offerName: string,
     *   checkInDates: string,
     *   people: string,
     *   priceText: string,
     *   button: string,
     *   url: string,
     *   buttonOnClick: string,
     *   buttonTarget: string
     * }} messageParams
     */

    getOfferTemplate(messageParams) {
        if (!messageParams.offerName) {
            return;
        }

        let _template = `
                <p class="messages-item-text">{{text}} {{offerName}}</p>
                {{if(options.checkInDates)}}
                    <p>
                        <span class="booster-app__text-icon booster-app__text-icon--calendar"></span>
                        <span class="messages-item-small-text">{{checkInDates}}</span>
                    </p>
                {{/if}}
                {{if(options.people)}}
                    <p>
                        <span class="booster-app__text-icon booster-app__text-icon--people"></span>
                        <span class="messages-item-small-text">{{people}}</span>
                    </p>
                {{/if}}
                {{if(options.priceText)}}
                    <span class="messages-item-price">{{priceText}}</span>
                {{/if}}
            `;

        _template += this.renderBtn(messageParams);

        let compiled = Sqrl.Compile(_template);

        return compiled(messageParams, Sqrl);
    }

    /**
     * @param {{
     *   title: string,
     *   text: string,
     *   button: string,
     *   url: string,
     *   buttonTarget: string
     *   image: string
     * }} messageParams
     */

    getAlertTemplate(messageParams) {

        if (messageParams.image) {
            if (!/^((http|https):\/\/)/.test(messageParams.image)) {
                messageParams.image = `${this.options.url}/${messageParams.image}`
            }
        }

        let _template = `
                {{if(options.image)}}
                  <div class="messages-item-image">
                    <img src="{{image}}" alt="">
                  </div>
                {{/if}}
                {{if(options.title)}}
                  <span class="messages-item-title">{{title}}</span>
                {{/if}}
                <p class="messages-item-small-text">{{text}}</p>
            `;

        _template += this.renderBtn(messageParams, 'messages-item-button--flat');

        let compiled = Sqrl.Compile(_template);

        return compiled(messageParams, Sqrl);
    }
    /**
     * @param {{
     *   title: string,
     *   text: string,
     *   button: string,
     *   url: string,
     *   buttonTarget: string
     *   image: string
     * }} messageParams
     */

    getVoucherTemplate(messageParams) {

        if (messageParams.image) {
            if (!/^((http|https):\/\/)/.test(messageParams.image)) {
                messageParams.image = `${this.options.url}/${messageParams.image}`
            }
        }

        let _template = `
                <div class="ribbon"></div>
                {{if(options.title)}}
                  <span class="messages-item-title">{{title}}</span>
                {{/if}}
            `;

        _template += this.renderBtn(messageParams, 'messages-item-button--flat');

        let compiled = Sqrl.Compile(_template);

        return compiled(messageParams, Sqrl);
    }

    getShareHospitalityTemplate(messageParams) {

        if (messageParams.image) {
            if (!/^((http|https):\/\/)/.test(messageParams.image)) {
                messageParams.image = `${this.options.url}/${messageParams.image}`
            }
        }

        let _template = `
                <div class="share"></div>
                {{if(options.title)}}
                  <span class="messages-item-title">{{title}}</span>
                {{/if}}
            `;

        _template += this.renderBtn(messageParams, 'messages-item-button--flat');

        let compiled = Sqrl.Compile(_template);

        return compiled(messageParams, Sqrl);
    }

    /**
     * @param {{
     *   title: string,
     *   subtitle: string,
     *   code: string,
     *   tag: string
     * }} messageParams
     */

    getDirectBookingTemplate(messageParams) {

        let _template = `
                <span class="messages-item-title">{{title}}</span>
                <span class="messages-item-subtitle">{{subtitle}}</span>
                <p class="messages-item-code">{{code}}</p>
                <span class="messages-item-tag">{{tag}}</span>    
            `;

        let compiled = Sqrl.Compile(_template);

        return compiled(messageParams, Sqrl);
    }

}

if(!window.app_booster) {
    window.app_booster = new AppBooster();
}
